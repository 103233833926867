// extracted by mini-css-extract-plugin
export var root = "ProductCard-module--root--1-zNb";
export var detailsContainer = "ProductCard-module--detailsContainer--j5O9z";
export var imageContainer = "ProductCard-module--imageContainer--AJJJN";
export var productName = "ProductCard-module--productName--5sF7I";
export var prices = "ProductCard-module--prices--zUNeH";
export var salePrice = "ProductCard-module--salePrice--MIaoB";
export var originalPrice = "ProductCard-module--originalPrice--QlHFx";
export var meta = "ProductCard-module--meta--B2Q5Z";
export var bagContainer = "ProductCard-module--bagContainer--OSVL1";
export var heartContainer = "ProductCard-module--heartContainer--8qcTl";
export var heartFillContainer = "ProductCard-module--heartFillContainer--EdY+V";
export var show = "ProductCard-module--show--buZwy";
export var hide = "ProductCard-module--hide--aQxe0";